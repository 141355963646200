/**
 * @file index.jsx
 * @description Home page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import MediumHero from "../components/mediumHero";
import Impressions from "../components/impressions";

/**
 * @function IndexPage
 * @returns {JSX.Element} Home page.
 */

function IndexPage() {
  return (
    <>
      <Meta
        title="Home"
        description="This extension adds the possibility to revoke all sessions (except your own)."
      />
      <MediumHero
        title="Revoke all sessions"
        subtitle="This extension adds the possibility to revoke all sessions (except your own)."
        buttonText="Download"
        buttonLink="/download"
      />
      <div className="section">
        <div className="container">
          <Impressions />
        </div>
      </div>
    </>
  );
}

export default IndexPage;
